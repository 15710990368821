import React from 'react';
import ReactDOM from 'react-dom';
import {ProductSearch} from "./components/product-search";


Array.from(document.getElementsByClassName("product-search")).forEach((element) => {
	ReactDOM.render(
		<React.StrictMode>
			<ProductSearch />
		</React.StrictMode>,
		element
	);
})

