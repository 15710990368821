import React from "react";
import DOMPurify from 'dompurify';
import {Filter} from "./product-search";

function XIcon({...props}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="5" height="6" viewBox="0 0 5 6" {...props}>
			<g fill="none" fillRule="evenodd" stroke="#FFF" transform="translate(.41 .29)">
				<line x1=".04" x2="3.56" y2="5.04"/>
				<line x1="3.53" y2="5.04"/>
			</g>
		</svg>
	);
}


export function SelectedFilters({activeFilters, setActiveFilters}:{activeFilters: Map<string, Array<Filter>>, setActiveFilters: (filters: Map<string, Array<Filter>>) => void}) {
	return (
		<div className="text-c_xs3 text-white overflow-hidden flex flex-wrap items-start gap-3 mt-4 mb-10 w-full min-h-c_selected_filters sm:min-h-0">
			{activeFilters && activeFilters.size !== 0 && Array.from(activeFilters.keys()).map((key, idx) => {
				return activeFilters.get(key).map((value, sidx) => {
					return (<div className="flex-grow-0 pl-2 py-2 bg-blue-100 rounded-5 cursor-pointer" onClick={() => {
						let cpActiveFilters = new Map(activeFilters)
						if (cpActiveFilters.has(key)){
							if (cpActiveFilters.get(key).length > 1){
								let active_values = cpActiveFilters.get(key)
								const index = active_values.indexOf(value);
								if (index > -1) {
									active_values.splice(index, 1);
								}
								cpActiveFilters.set(key, active_values)
							}else{
								cpActiveFilters.delete(key)
							}
						}
						setActiveFilters(cpActiveFilters)
					}} key={idx+sidx}>
						<div className="px-5 flex flex-row items-center justify-center">
							<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(value.filter_name)}}/>
							<XIcon className={"w-auto h-3 ml-2 "}/>
						</div>
					</div>);
				})
			})}
		</div>
	);
}
