import React from "react";
import {ProductSearchData} from "./product-search";
import {PuffLoader} from "react-spinners";
import {__} from "@wordpress/i18n";
import DOMPurify from 'dompurify';

export function Products({products, loading}: {products: Array<ProductSearchData> | null, loading: boolean}) {

	return (
		<div className={loading || (products && products.length == 0) ? "product_listing flex flex-row items-center mt-4 mb-c_B_lg2":"product_listing grid grid-cols-2 md:grid-cols-4 gap-5 w-full mb-c_B_lg2"} style={{minHeight: "5rem"}}>
			{!loading && products && products.map((p) => {
				return (<a target={p.meta.open_external_product_link_nt ? "_blank":"_self"} href={p.meta.has_external_product_link ? p.meta.external_product_link:p.link}
						   className="block product group flex-grow-0 relative overflow-hidden border border-gray-300 bg-white w-full pt-full relative md:w-c_md0  bg-cover bg-center bg-no-repeat"
						   key={p.id} style={{backgroundImage: `url(${p.meta.search_image !== undefined && p.meta.search_image.length !== 0? p.meta.search_image: p.image? p.image: ""})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}>
								<div className="product-title group-hover:hidden flex flex-col justify-end w-full h-full">
									<span className="block absolute w-full px-3 mb-3 text-center text-c_md2 font-semibold leading-7">
										{(p.meta.search_title !== undefined && p.meta.search_title.length !== 0) ? p.meta.search_title: p.title}
									</span>
								</div>
								<div className="product-description hidden group-hover:flex absolute top-0 bottom-0 right-0 left-0 w-full h-full text-white align-middle text-c_sm3 text-center bg-gray-c_bright4 p-c__md5 flex items-center justify-center" >
									<p className="block" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize((p.meta.search_description !== undefined && p.meta.search_description.length !== 0) ? p.meta.search_description: "") }} />
								</div>
						</a>);
			})}
			{products && products.length == 0 && !loading && <div className="flex flex-row h-full w-full items-center justify-center text-c_md">
				<p>{__("No Products", "ezagwpsupport-product-search")}</p>
			</div>}
			{loading && <div className={"flex flex-row h-full w-full items-center justify-center"}>
				<PuffLoader size={"60px"} color={"#10526a"}/>
			</div>}
		</div>
	);


}
