import React, {useState} from "react";
import {__} from "@wordpress/i18n";

function MagnifyingGlass({...props}) {

	return (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11">
				<g fill="none" fillRule="evenodd" stroke="#AA003C" transform="translate(.5 .5)">
					<circle cx="3.91" cy="3.91" r="3.91"/>
					<line x1="9.26" x2="6.63" y1="9.58" y2="6.92"/>
				</g>
		</svg>);
}


export function SearchBar({inputFieldValue, setInputFieldValue}:{inputFieldValue: string, setInputFieldValue: (value: string) => void}) {

	return (<div className="w-full p-3 border-c_0.3 border-black border-solid box-border items-center flex bg-gray-c_bright2 rounded-5 mb-5">
				<input className="flex-grow bg-gray-c_bright2 text-c_sm text-gray-c_mid pr-2 outline-none focus:outline-none"
				   name="product-search-words"
				   value={inputFieldValue}
				   onChange={(e) => setInputFieldValue(e.target.value as string)}
				   placeholder={__("Search for a specific product from our catalog", "ezagwpsupport-product-search")} />
				<MagnifyingGlass className="flex-grow-0 ml-2 inline-block h-c_sm"/>
			</div>
	)
}
