import React, {useCallback, useEffect, useRef, useState} from "react";
import {SearchBar} from "./search-bar";
import {Filters} from "./filters";
import {Products} from "./products";
import Fuse from "fuse.js";
import {SelectedFilters} from "./selectedFilters";
import {__} from "@wordpress/i18n";
import {useFetch} from "use-http";

export interface ProductSearchData {
	id: number,
	link: string,
	title: string
	image: string
	meta: {search_title: string,
		search_image: string,
		search_description: string,
		has_external_product_link: boolean,
		open_external_product_link_nt: boolean,
		external_product_link: string
	}
	terms: {
		"product-nuclides": Array<Term>
		"product-type": Array<Term>
		"product-application-area": Array<Term>
		"product-business-unit": Array<Term>
	}
}

export interface Term {
	id: number,
	name: string,
	slug: string,
}

export interface Filter {
	filter_name: string,
	filter_slug: string,
	count: number
}

const taxonomies = ["product-nuclides", "product-type", "product-application-area", "product-business-unit"]

function updateFilterOptions(data: Array<ProductSearchData>) {
	let updatedFilters = new Map<string, Array<{ filter_name: string, filter_slug: string, count: number }>>()
	if (data !== undefined && data !== null) {
		data.forEach((product) => {
			taxonomies.forEach((taxonomy) => {
				//@ts-ignore
				if (product.terms[taxonomy] !== undefined && product.terms[taxonomy] !== null) {
					//@ts-ignore
					product.terms[taxonomy].forEach((term) => {
						if (updatedFilters.get(taxonomy) === undefined) {
							updatedFilters.set(taxonomy, [])
						}
						let filter = updatedFilters.get(taxonomy).find((filter) => filter.filter_slug === term.slug)
						if (filter === undefined) {
							updatedFilters.get(taxonomy).push({
								filter_name: term.name,
								filter_slug: term.slug,
								count: 1
							})
						} else {
							filter.count++
						}
					})
				}
			})
		})
	}
		return updatedFilters
	}

function getFilterOptions(data: Array<ProductSearchData>) {
	let filters = new Map<string, Array<Filter>>()
	let prefilled: object
	let prefilledFilterStrings = new Map<string, Array<string>>()
	let prefilledFilters = new Map<string, Array<Filter>>()

	let url = new URL(window.location.href)
	if (url.searchParams.has("st")) {
		let searchTerms = decodeURIComponent(url.searchParams.get("st"))
		if (searchTerms[searchTerms.length - 1] === "/") {
			searchTerms = searchTerms.slice(0, -1);
		}
		try {
			prefilled = JSON.parse(atob(searchTerms))
			taxonomies.forEach((tax) => {
				if (prefilled.hasOwnProperty(tax)) {
					// @ts-ignore
					prefilledFilterStrings.set(tax, prefilled[tax])
				}
			})
		} catch (_) {
			prefilled = undefined
		}
	}

	if (data !== undefined) {
		data.forEach((product) => {
			taxonomies.forEach((tax) => {
				//@ts-ignore
				let terms = product.terms[tax]
				if (terms.length > 0) {
					terms.forEach((term: Term) => {
						if (filters.has(tax)) {
							let values = filters.get(tax)
							if (prefilledFilterStrings.has(tax)){
								prefilledFilterStrings.get(tax).forEach((filter_string) => {
									let value = values.find(item => item.filter_slug == filter_string)
									if (value !== undefined) {
										if (prefilledFilters.get(tax) !== undefined){
											let tmpPrefilledTaxFilters = prefilledFilters.get(tax)
											if (tmpPrefilledTaxFilters.find(item => item.filter_slug == value.filter_slug) === undefined) {
												tmpPrefilledTaxFilters.push(value)
												prefilledFilters.set(tax, tmpPrefilledTaxFilters)
											}
										}else {
											prefilledFilters.set(tax, [value])
										}
									}
								})
							}

							let value = values.find(item => item.filter_slug == term.slug)
							if (value !== undefined) {
								value.count += 1
							} else {
								values.push({filter_name: term.name, filter_slug: term.slug, count: 1})
							}
							filters.set(tax, values)
						} else {
							filters.set(tax, [{filter_name: term.name, filter_slug: term.slug, count: 1}])
						}
					})
				}
			})
		})
	}

	return {filters, prefilledFilters}
}

function applyFilters(data: Array<ProductSearchData>, activeFilters: null | Map<string, Array<Filter>>) {
	if (activeFilters == null) {
		return data
	}

	let terms = Array<string>()
	activeFilters.forEach((f) => {
		terms.push(...f.map((filter) => filter.filter_slug))
	})

	if (terms.length == 0) {
		return data
	}

	data = data.filter((product) => {
		let product_terms = Array<string>()

		taxonomies.forEach((tax) => {
			//@ts-ignore
			product.terms[tax].forEach((term: Term) => {
				product_terms.push(term.slug)
			})
		})
		return new Set([...terms].filter(x => !new Set([...product_terms]).has(x))).size == 0;
	})
	return data
}

	function searchData(data: Array<ProductSearchData>, searchWords: string) {
		if (searchWords.trim().length == 0) {
			return data
		}

		const options = {
			includeScore: true,
			ignoreLocation: true,
			threshold: 0.2,
			minMatchCharLength: searchWords.length < 3 ? searchWords.length : 3,
			keys: ['meta.search_title',
				'meta.search_description',
				'terms.product-nuclides.name',
				'terms.product-type.name',
				'terms.product-application-area.name',
				'terms.product-business-unit.name'
			]
		}

		const fuse = new Fuse(data, options)
		return fuse.search(searchWords).map(res => res.item)
	}

	function get_title(a: ProductSearchData) {
		return (a.meta.search_title.length !== 0) ? a.meta.search_title : a.title
	}

	function sortData(data: Array<ProductSearchData>) {
		return data.sort((a, b) => {
			let aTitle = get_title(a)
			let bTitle = get_title(b)
			if (aTitle < bTitle) {
				return -1;
			}
			if (aTitle > bTitle) {
				return 1;
			}
			return 0;
		})
	}

export function ProductSearch() {
	//@ts-ignore
	const currLang = currentLang
	const [products, setProducts] = useState<Array<ProductSearchData>>(undefined)
	const {get, response, loading} = useFetch({data: undefined})

	const [inputFieldValue, setInputFieldValue] = useState("")
	const [filters, setFilters] = useState<null | Map<string, Array<Filter>>>(null)
	const [inputFilteredFilters, setInputFilteredFilters] = useState<null | Map<string, Array<Filter>>>(null)
	const [active, setActiveFilters] = useState<null | Map<string, Array<Filter>>>(null)

	const [taxonomyNames, setTaxonomyNames] = useState<null | Map<string, string>>(null)
	const [filteredData, setFilteredData] = useState<Array<ProductSearchData>>()
	const [limit, setLimit] = useState(12)

	const ps_ref = useRef(null)
	const executeScroll = () => ps_ref.current.scrollIntoView()

	const loadInitial = useCallback(async () => {
		const url = "/wp-json/ezagwpsupport/v1/products"
		const products_data = await get(currLang !== undefined ? "/" + currLang + url : url)

		const taxonomy_map = new Map<string, string>()
		taxonomy_map.set("product-nuclides", products_data["product-nuclides"])
		taxonomy_map.set("product-type", products_data["product-type"])
		taxonomy_map.set("product-application-area", products_data["product-application-area"])
		taxonomy_map.set("product-business-unit", products_data["product-business-unit"])
		setTaxonomyNames(taxonomy_map)
		if (response.ok) setProducts(products_data["products"])
	}, [get, response])

	useEffect(() => {
		loadInitial()
	}, [loadInitial])

	useEffect(() => {
		let url = new URL(window.location.href)
		let ifv = ""

		if ((url.searchParams.has("sw") || url.searchParams.has("st") )&& ps_ref && ps_ref.current){
			executeScroll()
		}

		if (url.searchParams.has("sw")) {
			ifv = decodeURIComponent(url.searchParams.get("sw"))
			if (ifv[ifv.length - 1] === "/") {
				ifv = ifv.slice(0, -1);
			}
			setInputFieldValue(ifv)
			if (ps_ref && ps_ref.current){
				executeScroll()
			}
		}
	}, [])

	useEffect(() => {
		if (products !== undefined) {
			let filter_options = getFilterOptions(products)
			setFilters(filter_options.filters)
			setInputFilteredFilters(filter_options.filters)
			setActiveFilters(filter_options.prefilledFilters)
			let filteredData = applyFilters(products, filter_options.prefilledFilters)

			let url = new URL(window.location.href)
			let ifv = inputFieldValue
			if (url.searchParams.has("sw")) {
				ifv = decodeURIComponent(url.searchParams.get("sw"))
				if (ifv[ifv.length - 1] === "/") {
					ifv = ifv.slice(0, -1);
				}
			}
			setInputFieldValue(ifv)

			filteredData = searchData(filteredData, ifv)
			if (ifv.length == 0) {
				filteredData = sortData(filteredData);
			}
			setInputFilteredFilters(updateFilterOptions(filteredData))

			setFilteredData(filteredData)
		}
	}, [products])

	useEffect(() => {
		if (products !== undefined) {
			let filteredData = applyFilters(products, active)
			if (products !== null) {
				filteredData = searchData(filteredData, inputFieldValue)
				if (inputFieldValue.length == 0) {
					filteredData = sortData(filteredData);
				}
				setInputFilteredFilters(updateFilterOptions(filteredData))
			}
			setFilteredData(filteredData)
		}
	}, [inputFieldValue, active])


	let slicedData = new Array<ProductSearchData>()
	if (filteredData !== undefined && filteredData !== null) {
		slicedData = [...filteredData]
		slicedData = slicedData.slice(0, limit)
	}

	return (<div ref={ps_ref} className="container mx-auto max-w-5xl pt-0 sm:pt-c_T_md2 pb-c_T_md2 px-4 tablet:px-0">
		<div className="w-full relative flex flex-col-reverse sm:flex-col items-center mb-4 sm:mb-0">
			<div className="w-full relative flex flex-col items-center">
				<SearchBar inputFieldValue={inputFieldValue} setInputFieldValue={setInputFieldValue}/>
				<Filters filters={inputFilteredFilters} taxonomies={taxonomyNames} active_filters={active}
						 setFilterState={setActiveFilters}/>
			</div>
			<SelectedFilters activeFilters={active} setActiveFilters={setActiveFilters}/>
		</div>
		<Products products={slicedData}
				  loading={loading || filters == null}/>
		{filteredData !== undefined && filteredData !== null && filteredData.length > limit &&
			<button onClick={() => setLimit(products.length)}
					className="w-full bg-red hover:bg-red-20p_darker text-white text-c_sm rounded-5 pt-c_T_xs3 pb-c_B_xs2">
				{__("Show All", "ezagwpsupport-product-search")}
			</button>}
	</div>);
}